import React from 'react';
import styledComponents from 'styled-components';
import { Link } from 'gatsby';
import treatmentIcons from '../icons/treatmentIcons';
import THEME from '../../themes';
import { actualPathContains } from '../../utilities/windowManagement';

const TreatmentsContainer = styledComponents.div`
  max-width: 1100px;
  margin: 130px auto;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

  @media screen and (max-width: 1290px){
    max-width: 90%;
  }
`;

const TreatmentsCardSlim = styledComponents.div`
  width: 239px;
  height: 70px;  
  background: ${THEME.COLORS.WHITE} 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #81818180;
  border-radius: 35px;
  opacity: 1;
  font: normal normal normal 16px/26px PT Sans;
  letter-spacing: 0.48px;
  display: flex;
  align-items: center;
  margin: 20px 10px; 

  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0;
    margin: 0;
    color: ${THEME.COLORS.PRIMARY};
  }

  &.selected {
    background: ${THEME.COLORS.PRIMARY} 0% 0% no-repeat padding-box;
  
    a {
      color: ${THEME.COLORS.WHITE};
    }
  }

  p {
    margin: 0;
    padding: 0 20px;
  }

  
`;

const TreatmentsIcon = styledComponents.img`
  width: 70px;
  margin: -11px -11px;
  background-color: ${THEME.COLORS.WHITE};
  padding: 0;
  border-radius: 40px;
`;

const TreatmentsTitle = styledComponents.p`
  text-transform: uppercase;

  strong {
    font: normal normal bold 22px/26px PT Sans;
  }
`;

const getClassNameForRoute = (route) => (actualPathContains(route) ? 'selected' : '');

const TreatmentsSlim = () => (
  <>
    <TreatmentsContainer>
      <TreatmentsCardSlim className={getClassNameForRoute('dermatologia_clinica')}>
        <Link to='/tratamentos/dermatologia_clinica'>
          <TreatmentsIcon src={treatmentIcons.iconClinica} alt="ícone dermatologia clínica"/>
          <TreatmentsTitle>dermatologia<br /><strong>Clínica</strong></TreatmentsTitle>
        </Link>
      </TreatmentsCardSlim>
      <TreatmentsCardSlim className={getClassNameForRoute('dermatologia_cirurgica')} alt="ícone dermatologia cirúrgica">
        <Link to='/tratamentos/dermatologia_cirurgica'>
          <TreatmentsIcon src={treatmentIcons.iconCirurgica} />
          <TreatmentsTitle>dermatologia<br /><strong>cirúrgica</strong></TreatmentsTitle>
        </Link>
      </TreatmentsCardSlim>
      <TreatmentsCardSlim className={getClassNameForRoute('dermatologia_estetica')} alt="ícone dermatologia estética">
        <Link to='/tratamentos/dermatologia_estetica'>
          <TreatmentsIcon src={treatmentIcons.iconEstetica} />
          <TreatmentsTitle>dermatologia<br /><strong>estética</strong></TreatmentsTitle>
        </Link>
      </TreatmentsCardSlim>
      <TreatmentsCardSlim className={getClassNameForRoute('tratamentos_capilares')} alt="ícone tratamentos capilares">
        <Link to='/tratamentos/tratamentos_capilares'>
          <TreatmentsIcon src={treatmentIcons.iconCapilar} />
          <TreatmentsTitle>tratamentos<br /><strong>capilares</strong></TreatmentsTitle>
        </Link>
      </TreatmentsCardSlim>
    </TreatmentsContainer>
  </>
);

export default TreatmentsSlim;
