/* eslint-disable react/prop-types */
import React from 'react';
import styledComponents from 'styled-components';
import THEME from '../../themes';
import { blockScroll, changeVisibility, unblockScroll } from '../../utilities/windowManagement';

const Overlay = styledComponents.div`
  position: fixed;
  background: #ECECEC 0% 0% no-repeat padding-box;
  opacity: 0.78;

  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 100;
  display: none;
`;

const Overlay2 = styledComponents.div`
  position: fixed;
  opacity: 1;

  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 100;
  display: none;
  overflow-x: scroll;
`;

const ModalContainer = styledComponents.div`
  position: relative;
  width: 80%;
  max-width: 1200px;
  height: auto;
  min-height: 50%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #81818180;
  opacity: 1;
  z-index: 110;
  top: 20%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 300px;
  display: flex;
  justify-content: flex-start;
  align-items: top;
  padding: 26px;

  p {
    font: normal normal normal 16px/26px PT Sans;
    letter-spacing: 0.48px;
    color: #6D6D6D;
    max-width: 95%;
    padding: 0;
  }

  p.lead {
    margin-top: 20px;
    font: normal normal bold 22px/26px PT Sans;
    letter-spacing: 0.66px;
    color: #D0AD67;
    padding: 0;
    margin-bottom: -12px;
  }

  p.title {
    font: normal normal bold 22px/21px PT Sans;
    letter-spacing: 0px;
    color: #241F59;
    opacity: 1;
    margin-bottom: 40px;
  }

  div.line {
    border: 1px dashed #D0AD67;
    opacity: 1;
    width: 30%;
    heigth: 1px;
  }

  @media screen and (max-width: 699px){
    flex-wrap: wrap;
    justify-content: center;

    p { 
      max-width: 90%;
    }
  }

`;

const Icon = styledComponents.img`
  width: 80px;
  max-height: 80px;
  margin: -30px 16px -30px -60px;
  background-color: ${THEME.COLORS.WHITE};
  padding: 0;
  border-radius: 40px;
  box-shadow: 0px 3px 6px #00000029;
`;

const PlusButton = styledComponents.a`
  display: block;
  text-align: center;
  font: normal normal bold 22px/21px PT Sans;
  color: #241F59;
  width: 30px;
  height: 30px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #81818180;
  border-radius: 15px;
  opacity: 1;
  line-height: 30px;
  cursor: pointer;
`;

const CloseButton = styledComponents.a`
  position: absolute !important;
  top: 10px;
  right: 5px;
  left: auto;
  display: block;
  text-align: center;
  font: normal normal bold 22px/21px PT Sans;
  color: ${THEME.COLORS.PRIMARY};
  width: 30px;
  height: 30px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #81818180;
  border-radius: 15px;
  border: 1px solid ${THEME.COLORS.PRIMARY};
  opacity: 1;
  line-height: 30px;
  cursor: pointer;
`;

const onOpen = (id) => {
  changeVisibility(`#ov1${id}`);
  changeVisibility(`#ov2${id}`);
  blockScroll();
};

const onClose = (id) => {
  changeVisibility(`#ov1${id}`);
  changeVisibility(`#ov2${id}`);
  unblockScroll();
};

const AdditionalInfo = ({
  icon, title, content, nid,
}) => (
  <>
    <PlusButton onClick={() => onOpen(nid)}>+</PlusButton>
    <Overlay id={`ov1${nid}`} onClick={() => onClose(nid)}/>
    <Overlay2 id={`ov2${nid}`} >
      <ModalContainer>
        <CloseButton onClick={() => onClose(nid)}>X</CloseButton>
        <Icon src={icon} />
        <div>
          <p className='title'>{title}</p>
          <p>
            {content}
          </p>
        </div>
      </ModalContainer>
    </Overlay2>
  </>
);

export default AdditionalInfo;
