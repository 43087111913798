import * as React from 'react';
import styledComponents from 'styled-components';
import { Layout } from '../layout';
import Banner from '../banners';
import GrayRing from '../../images/Circulo-Prateado.png';
import TreatmentsSlim from '../treatments/slim';
import THEME from '../../themes';
import AdditionalInfo from '../modal';

const TopContainer = styledComponents.div`
  background: transparent linear-gradient(180deg, #FFFFFF00 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
  opacity: 1;
  width: 100%;
  padding-bottom: 40px;
  margin-bottom: 0;

  background-image: url(${GrayRing});
  background-position: calc(50% + 800px) 250px;
  background-repeat: no-repeat;
  background-size: 800px 800px;
  z-index: 1;
`;

const DiseaseRow = styledComponents.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  max-width: 95%;
  min-width: 90%;
  margin: 40px auto;

  @media screen and (max-width: 1024px){
    flex-wrap: wrap;
    max-width: 90%;
  }
`;

const DiseaseCard = styledComponents.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  font: normal normal bold 22px/21px PT Sans;
  letter-spacing: 0px;
  color: #241F59;

  width: 47%;
  height: 60px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #81818180;
  opacity: 1;

  a {
    position: absolute;
    right: 10px;
  }

  @media screen and (max-width: 1024px){
    min-width: 100%;
    max-width: 100%;
    width: 100%;
    
    &:last-child {
      margin-top 40px;
    }
  }

  @media screen and (max-width: 699px){
    flex-wrap: wrap;
    justify-content: center;
    min-width: 90%;
    max-width: 90%;
    width: 90%;
    height: auto;

    &:last-child {
      margin-top: 80px;
    }

    a {
      top: 5px;
    }

    &:first-child {
      margin-top: 40px;
    }
    
    p {
      display: block;
      width: 100%;
      min-width: 83%;
      text-align: left;
      padding: 0 16px;
    }

    img {
      margin: -65px 0px 0px 0px;
    }
  }
`;

const DiseaseIcon = styledComponents.img`
  width: 80px;
  margin: -11px 16px -11px -16px;
  background-color: ${THEME.COLORS.WHITE};
  padding: 0;
  border-radius: 40px;
  box-shadow: 0px 3px 6px #00000029;
`;

const createDiseaseCard = (disease, id) => (
  <DiseaseCard>
    <DiseaseIcon src={disease.icon} />
    <p style={{ maxWidth: disease.content ? '70%' : 'auto' }}>{disease.name}</p>

    {
      disease.content
        ? (
        <AdditionalInfo
          icon={disease.icon}
          title={disease.name}
          content={disease.content}
          nid={id}
        />
        )
        : ''
    }
  </DiseaseCard>
);

export const createDiseaseRows = (diseaseList) => {
  let pair = [];
  const rows = [];

  for (let i = 0; i < diseaseList.length; i++) {
    if (pair.length === 2) {
      rows.push(<DiseaseRow>{pair}</DiseaseRow>);
      pair = [];
    }
    pair.push(createDiseaseCard(diseaseList[i], String(i)));
  }

  if (pair.length > 0 && pair.length < 2) {
    pair.push(<DiseaseCard style={{ visibility: 'hidden' }} />);
  }

  rows.push(<DiseaseRow>{pair}</DiseaseRow>);
  return rows;
};

const meta = [
  <meta key='description' name='description' content={'Clínica Dermatológica Angelucci - Dermatologia Cirúrgica, Clínica, Estética e Tratamentos Capilares.'} />,
];

// eslint-disable-next-line react/prop-types
const TreatmentsLayout = ({ title = '', pageName, children }) => (
  <>
  <Layout title={`Clínica Dermatológica Angelucci - Tratamentos${title ? ` - ${title}` : ''}`} meta={meta}>
    <main>
      <TopContainer>
        <Banner name={pageName}>a sua saúde merece o melhor em<strong>tratamentos</strong></Banner>
        <TreatmentsSlim />
        {children}
      </TopContainer>
    </main>
  </Layout>
  </>
);

export default TreatmentsLayout;
